var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "start-a-business" },
        [
          _c("progress-steps", {
            attrs: {
              subtitle: _vm.subtitle,
              "progress-status": _vm.progressStatus,
            },
          }),
          _vm.convertingCompany
            ? _c(
                "div",
                { staticClass: "main-container" },
                [
                  _c("h2", { staticClass: "sl__h2 container-header" }, [
                    _vm._v("\n      Adding "),
                    _c("span", { staticClass: "company-name" }, [
                      _vm._v(_vm._s(_vm.newFullCompanyName)),
                    ]),
                    _vm._v(" to your account.\n    "),
                  ]),
                  _c("ct-centered-spinner"),
                ],
                1
              )
            : _c("div", { staticClass: "main-container" }, [
                _c("h2", { staticClass: "sl__h2 container-header" }, [
                  _vm._v(
                    "\n      Great! Let's create the company record in your account.\n    "
                  ),
                ]),
                _c("div", { staticClass: "container-body" }, [
                  _c("div", { staticClass: "company-data" }, [
                    _c("ul", [
                      _c("li", { staticClass: "sl__li" }, [
                        _vm._v("\n            Company Name:\n          "),
                      ]),
                      _c("li", { staticClass: "sl__li" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.newFullCompanyName) +
                            "\n          "
                        ),
                      ]),
                      _c("li", { staticClass: "sl__li" }, [
                        _vm._v("\n            Jurisdiction:\n          "),
                      ]),
                      _c("li", { staticClass: "sl__li" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.jurisdiction.state_province_region) +
                            "\n          "
                        ),
                      ]),
                      _c("li", { staticClass: "sl__li" }, [
                        _vm._v("\n            Entity Type:\n          "),
                      ]),
                      _c("li", { staticClass: "sl__li" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.entityType.name) +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "svg-container" },
                    [
                      _c("font-awesome-svg", {
                        attrs: { type: "checklist", fill: "black" },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
          !_vm.convertingCompany
            ? _c("button-container", {
                attrs: {
                  "next-button-label": _vm.nextButtonLabel,
                  "show-previous-button": _vm.showPreviousButton,
                },
                on: { next: _vm.convertDomainToCompany },
              })
            : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "center-spinner" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }