<template>
  <div v-if="loaded" class="start-a-business">
    <progress-steps
      :subtitle="subtitle"
      :progress-status="progressStatus"
    />

    <div v-if="convertingCompany" class="main-container">
      <h2 class="sl__h2 container-header">
        Adding <span class="company-name">{{ newFullCompanyName }}</span> to your account.
      </h2>
      <ct-centered-spinner />
    </div>

    <div v-else class="main-container">
      <h2 class="sl__h2 container-header">
        Great! Let's create the company record in your account.
      </h2>
      <div class="container-body">
        <div class="company-data">
          <ul>
            <li class="sl__li">
              Company Name:
            </li>
            <li class="sl__li">
              {{ newFullCompanyName }}
            </li>
            <li class="sl__li">
              Jurisdiction:
            </li>
            <li class="sl__li">
              {{ jurisdiction.state_province_region }}
            </li>
            <li class="sl__li">
              Entity Type:
            </li>
            <li class="sl__li">
              {{ entityType.name }}
            </li>
          </ul>
        </div>
        <div class="svg-container">
          <font-awesome-svg :type="'checklist'" :fill="'black'" />
        </div>
      </div>
    </div>

    <button-container
      v-if="!convertingCompany"
      :next-button-label="nextButtonLabel"
      :show-previous-button="showPreviousButton"
      @next="convertDomainToCompany"
    />
  </div>

  <div v-else class="center-spinner">
    <ct-centered-spinner />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'ConfirmCompany',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    FontAwesomeSvg:           () => import('@/components/shared/FontAwesomeSvg'),
    ProgressSteps: () => import('@/components/StagelineV2/domainsFirst/shared/ProgressSteps'),
    ButtonContainer: () => import('@/components/StagelineV2/domainsFirst/shared/ButtonContainer'),
  },
  mixins: [makeToastMixin],
  data () {
    return {
      subtitle: 'company name',
      progressStatus: 80,
      nextButtonLabel: 'Confirm and add company',
      showPreviousButton: true,
      convertingCompany: false,
    }
  },
  computed: {
    ...mapGetters('stagelineCreateCompany', [
      'jurisdiction',
      'entityType',
      'entityTypeSuffix',
      'companyName',
      'loaded',
    ]),
    ...mapGetters('stageline', [
      'accountCompanies',
    ]),
    newFullCompanyName() {
      return this.entityTypeSuffix ? this.companyName + ' ' + this.entityTypeSuffix : this.companyName
    },
  },
  async mounted() {
    this.showLoading()
  },
  methods: {
    ...mapActions('stageline', [
      'getAccountCompaniesSimple',
      'loadPeriod',
      'loadPeriodDeep',
    ]),
    ...mapActions('stagelineCreateCompany', [
      'convertCompany',
      'setCreatingCompany',
      'setCurrentSlide',
      'setLoaded',
    ]),
    ...mapActions('dashpanel', [
      'setIsEntityTypeDomains',
    ]),
    async convertDomainToCompany() {
      this.convertingCompany = true
      const companyId = this.$route.params.companyId || this.$route.path.split('/').pop()

      try {
        const result = await this.convertCompany(companyId)

        if (result?.success) {
          await this.processConvertingCompany()
        } else {
          this.errorToast('Error', 'An error has occurred converting the company on your account')
        }
      }
      catch(error) {
        this.errorToast('Error', 'An unexpected error occurred while converting the company')
      }
      finally {
        this.convertingCompany = false
      }
    },
    async processConvertingCompany() {
      const periodName = 'start'

      try {
        this.setCreatingCompany(true)
        await this.getAccountCompaniesSimple()
        await this.setIsEntityTypeDomains()
        await this.loadPeriodDeep( { periodIdOrName: periodName })
        await this.loadPeriod(periodName)
        await this.setCurrentSlide('CompanyCreated')
      }
      catch(_error) {
        this.errorToast('Error', 'An unexpected error occurred while processing the company')
      }
    },
    showLoading() {
      this.setLoaded(false)
      setTimeout(() => {  this.setLoaded(true) }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.sl__h2 {
  text-align: left;
}

.start-a-business {

  .main-container {

    .container-header {

      .company-name {
        color: $sl__violet2;
      }
    }

    .container-body {
      @include sl__flex(row, space-between, center);
      flex-wrap: wrap;

      .company-data {
        padding-right: 2.0em;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: grid;
          grid-template-columns: minmax(100px, 1fr) minmax(200px, 2fr);
          justify-items: flex-start;
          align-items: center;
          column-gap: 1.2em;

          li {
            padding: 0.6em 0;

            &:nth-child(odd) {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .start-a-business {

    .main-container {

      .container-body {
        justify-content: center;
        row-gap: 2.0em;
        padding: 0;

        .company-data {
          padding-right: 0;
        }
      }
    }
  }
}
</style>
